function Footer() {
  return (
    <>
      {/* <div id="footer">
        Developed by Me - Adeyomola Kazeem; Designed By Hyperthesis Technologies
      </div> */}
    </>
  );
}
export default Footer;
